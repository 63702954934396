import React from 'react'
import { Link } from 'react-router-dom'

const featuredPosts = [
  {
    id: '2024-reflection',
    title: '2024 Year in Review: Adaptation',
    description: 'Looking back on the year of change and adaptation.',
    image:
      'https://cdn.pixabay.com/photo/2024/07/02/08/55/new-years-day-8867134_640.jpg',
  },
]

const LatestPosts: React.FC<{ className?: string }> = ({ className }) => {
  return (
    <div className={className}>
      <h2 className="text-xl font-semibold text-gray-800 mb-4">Latest posts</h2>
      <div className="space-y-4">
        {featuredPosts.map((post) => (
          <Link
            key={post.id}
            to={`/blog/${post.id}`}
            className="block group bg-gray-50 border border-gray-200 
                       rounded-md shadow-sm hover:shadow-md transition 
                       overflow-hidden"
          >
            <div className="h-40 overflow-hidden">
              <img
                src={post.image}
                alt={post.title}
                className="w-full h-full object-cover 
                           group-hover:scale-105 transition"
              />
            </div>
            <div className="p-4">
              <h3 className="text-lg font-semibold text-gray-800 group-hover:text-blue-600">
                {post.title}
              </h3>
              <p className="text-sm text-gray-600 mt-2">{post.description}</p>
            </div>
          </Link>
        ))}
      </div>
    </div>
  )
}

export default LatestPosts
