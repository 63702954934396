// src/components/SpotifyCurrentlyPlaying.tsx
import { Volume2, VolumeOff } from 'lucide-react'
import React, { useEffect, useState } from 'react'
import { useSpotify } from '../../context/SpotifyContext'
import LinearProgressBar from '../LinearProgressBar'
import SpotifyTrackCommon from './SpotifyTrackCommon'

const SpotifyCurrentlyPlaying: React.FC<{ className?: string }> = ({
  className,
}) => {
  const {
    togglePlayback,
    currentlyPlaying,
    isListeningAlong,
    setIsListeningAlong,
  } = useSpotify()

  const {
    item: track,
    progress_ms: progressMs,
    is_playing: isDavidPlaying,
  } = currentlyPlaying || { track: null, progress_ms: null, is_playing: false }

  const [displayedProgress, setDisplayedProgress] = useState<number>(
    progressMs ?? 0,
  )

  useEffect(() => {
    if (!progressMs || !track) return
    setDisplayedProgress(progressMs)
    const interval = setInterval(() => {
      setDisplayedProgress((prev) => Math.min(prev + 1000, track.duration_ms))
    }, 1000)

    if (!isDavidPlaying) {
      clearInterval(interval)
    }

    return () => clearInterval(interval)
  }, [progressMs, isDavidPlaying, track])

  return (
    <div className={className}>
      <h2 className="text-xl font-semibold text-gray-800 mb-4">
        {"What I'm listening to right now"}
      </h2>
      {track && isDavidPlaying ? (
        <SpotifyTrackCommon
          track={track}
          button={
            <button
              onClick={() => {
                togglePlayback(track.id, displayedProgress)
                setIsListeningAlong(!isListeningAlong)
              }}
              className="p-2 bg-blue-500 hover:bg-blue-600 rounded flex items-center justify-center"
            >
              {isListeningAlong ? (
                <Volume2 size={20} className="text-white" />
              ) : (
                <VolumeOff size={20} className="text-white" />
              )}
            </button>
          }
        >
          <LinearProgressBar
            currentMs={displayedProgress}
            totalMs={track.duration_ms}
          />
        </SpotifyTrackCommon>
      ) : (
        <div className="p-2 bg-white rounded-md shadow h-[80px] flex flex-col align-center items-center justify-center">
          <p className="text-gray-400 font-bold text-sm">
            David is not currently listening to music
          </p>
        </div>
      )}
    </div>
  )
}

export default SpotifyCurrentlyPlaying
