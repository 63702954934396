// src/components/SpotifyTrackCommon.tsx
import React from 'react'
import { Track } from '../../types/spotify'
import MarqueeText from '../MarqueeText'

interface SpotifyTrackCommonProps {
  track: Track
  button?: React.ReactNode
  children?: React.ReactNode
}

const SpotifyTrackCommon: React.FC<SpotifyTrackCommonProps> = ({
  track,
  button,
  children,
}) => {
  return (
    <div className="p-2 bg-white rounded-md shadow">
      <div className="flex items-center w-full">
        {/* Clickable album image */}
        <a
          href={track.album.external_urls.spotify}
          target="_blank"
          rel="noreferrer"
          className="flex-shrink-0"
        >
          <img
            src={track.album.images[0]?.url}
            alt={track.name}
            className="w-16 h-16 object-cover rounded mr-4"
          />
        </a>
        {/* Track details with marquee text */}
        <div className="flex-1 overflow-hidden mr-1">
          <div className="group">
            <MarqueeText
              items={[{ text: track.name, link: track.external_urls.spotify }]}
              className="font-semibold text-sm"
            />
          </div>
          <div className="group mt-1">
            <MarqueeText
              items={track.artists.map((artist) => ({
                text: artist.name,
                link: artist.external_urls.spotify,
              }))}
              className="font-medium text-sm text-gray-600"
            />
          </div>
        </div>
        {/* Custom button */}
        {button}
      </div>
      {children}
    </div>
  )
}

export default SpotifyTrackCommon
