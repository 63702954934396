// src/App.tsx
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Blog from './pages/Blog';
import BlogPost from './pages/BlogPost';
import Navbar from './components/Navbar';
import { SpotifyProvider } from './context/SpotifyContext';
import './App.css';

function App() {
  return (
    <SpotifyProvider>
      <Router>
        {/* The new shrinking navbar */}
        <Navbar />

        {/* Add some top padding so content starts below the fixed nav */}
        <main className="mx-auto max-w-5xl p-4 pt-20">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/blog/:id" element={<BlogPost />} />
          </Routes>
          {/* Footer (Optional) */}
          <footer className="text-gray-500 py-6 text-center">
            <p className="text-sm">
              © {new Date().getFullYear()} David Dalmaso. All rights reserved.
            </p>
          </footer>
        </main>
      </Router>
    </SpotifyProvider>
  );
}

export default App;
