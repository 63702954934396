// src/context/SpotifyContext.tsx
import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  ReactNode,
  useCallback,
} from 'react'
import {
  TimeRange,
  Track,
  SpotifyApiResponse,
  CurrentlyPlayingInfo,
  SpotifyPlayerState,
} from '../types/spotify'

// Define the base API URL from environment variables.
const API_BASE = process.env.REACT_APP_API_URL || 'http://localhost:8000'

declare global {
  interface Window {
    onSpotifyWebPlaybackSDKReady: () => void
    Spotify: any
  }
}

export interface SpotifyContextProps {
  tracks: Track[]
  loading: boolean
  error: string | null
  player: any
  deviceId: string | null
  token: string | null
  currentTrackId: string | null
  isPlaying: boolean
  isListeningAlong: boolean
  setIsListeningAlong: React.Dispatch<React.SetStateAction<boolean>>
  togglePlayback: (trackId: string, positionMs?: number) => void
  topTracksTimeRange: TimeRange
  setTopTracksTimeRange: (timeRange: TimeRange) => void
  isLoadingTopTracks: boolean
  currentlyPlaying: CurrentlyPlayingInfo | null
  loginToSpotify: () => void
}

const SpotifyContext = createContext<SpotifyContextProps | undefined>(undefined)

export const SpotifyProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [tracks, setTracks] = useState<Track[]>([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)

  // Web Playback SDK state.
  const [deviceId, setDeviceId] = useState<string | null>(null)
  const [token, setToken] = useState<string | null>(null)
  const [player, setPlayer] = useState<any>(null)
  const [userPlayerState, setUserPlayerState] =
    useState<SpotifyPlayerState | null>(null)
  const [isListeningAlong, setIsListeningAlong] = useState<boolean>(false)
  const [topTracksTimeRange, setTopTracksTimeRange] = useState<TimeRange>(
    TimeRange.MEDIUM_TERM,
  )
  const [isLoadingTopTracks, setIsLoadingTopTracks] = useState<boolean>(false)
  const [currentlyPlaying, setCurrentlyPlaying] =
    useState<CurrentlyPlayingInfo | null>(null)
  const [topTracksTimeRangeCache, setTopTracksTimeRangeCache] = useState<
    Record<TimeRange, Track[]>
  >({
    [TimeRange.SHORT_TERM]: [],
    [TimeRange.MEDIUM_TERM]: [],
    [TimeRange.LONG_TERM]: [],
  })

  // Derived values from user player state
  const currentTrack = userPlayerState?.track_window.current_track
  const currentTrackId = currentTrack?.id ?? null
  const isPlaying = userPlayerState?.paused === false

  // Fetch David's currently playing track from Spotify.
  useEffect(() => {
    const fetchCurrentlyPlaying = async () => {
      try {
        const res = await fetch(
          `${API_BASE}/api/music/spotify/currently-playing`,
        )
        // Spotify returns a 204 if nothing is currently playing.
        if (res.status === 204) {
          setCurrentlyPlaying(null)
          return
        }
        if (!res.ok) {
          throw new Error('Failed to fetch currently playing track')
        }
        const data = await res.json()
        if (data && data.item) {
          setCurrentlyPlaying(data)
        } else {
          setCurrentlyPlaying(null)
        }
      } catch (err) {
        console.error('Error fetching currently playing track:', err)
      }
    }

    // Initial fetch and then poll every 10 seconds.
    fetchCurrentlyPlaying()
    const interval = setInterval(fetchCurrentlyPlaying, 10000)
    return () => clearInterval(interval)
  }, [])

  // Fetch top tracks once.
  useEffect(() => {
    if (topTracksTimeRangeCache[topTracksTimeRange].length > 0) {
      setTracks(topTracksTimeRangeCache[topTracksTimeRange])
      return
    }
    setIsLoadingTopTracks(true)
    fetch(
      `${API_BASE}/api/music/spotify/top-tracks?time_range=${topTracksTimeRange}`,
    )
      .then((res) => {
        if (!res.ok) throw new Error('Failed to fetch top tracks')
        return res.json()
      })
      .then((data: SpotifyApiResponse) => {
        setTracks(data.items)
        setTopTracksTimeRangeCache((prev) => ({
          ...prev,
          [topTracksTimeRange]: data.items,
        }))
      })
      .catch((err) => {
        console.error(err)
        setError('Unable to load top tracks.')
      })
      .finally(() => {
        setIsLoadingTopTracks(false)
        setLoading(false)
      })
  }, [topTracksTimeRange, topTracksTimeRangeCache])

  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://sdk.scdn.co/spotify-player.js'
    script.async = true
    document.body.appendChild(script)

    window.onSpotifyWebPlaybackSDKReady = () => {
      fetch(`${API_BASE}/api/music/spotify/current-access-token`, {
        method: 'GET',
        credentials: 'include', // Ensures cookies (session cookie) are sent
      })
        .then((res) => res.json())
        .then((data) => {
          if (!data.access_token) {
            return
          }
          const accessToken = data.access_token
          setToken(accessToken)

          // Initialize the Spotify Player with the access token.
          const newPlayer = new window.Spotify.Player({
            name: "David Dalmaso's Weblog Player",
            getOAuthToken: (cb: (token: string) => void) => cb(accessToken),
            volume: 0.5,
          })

          // Listen for when the player is ready.
          newPlayer.addListener(
            'ready',
            ({ device_id }: { device_id: string }) => {
              console.log('Player ready with Device ID:', device_id)
              setDeviceId(device_id)
            },
          )

          // Listen for player state changes.
          newPlayer.addListener(
            'player_state_changed',
            (state: SpotifyPlayerState) => {
              setUserPlayerState(state)
            },
          )

          // Error logging.
          const logError =
            (name: string) =>
            ({ message }: { message: string }) =>
              console.error(`${name}: ${message}`)
          newPlayer.addListener(
            'initialization_error',
            logError('Initialization Error'),
          )
          newPlayer.addListener(
            'authentication_error',
            logError('Authentication Error'),
          )
          newPlayer.addListener('account_error', logError('Account Error'))
          newPlayer.addListener('playback_error', logError('Playback Error'))

          newPlayer.connect().then((success: boolean) => {
            if (success) console.log('Connected to Spotify Web Playback SDK!')
          })
          setPlayer(newPlayer)
        })
        .catch((err) => console.error('Error fetching access token:', err))
    }

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  // Play a track via the Spotify Web API.
  const playTrack = useCallback(
    (trackId: string, positionMs?: number) => {
      if (!deviceId || !token) {
        console.error('No device ID or token available')
        return
      }
      const trackUri = `spotify:track:${trackId}`
      fetch(`https://api.spotify.com/v1/me/player/play?device_id=${deviceId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          uris: [trackUri],
          ...(positionMs && { position_ms: positionMs }),
        }),
      })
        .then((res) => {
          if (!res.ok) console.error('Error playing track:', res)
        })
        .catch((err) => console.error('Error in playTrack:', err))
    },
    [deviceId, token],
  )

  // If David's currently playing track changes and the user is listening along, update the player.
  useEffect(() => {
    if (!isListeningAlong) return

    // If nothing is currently playing, do nothing.
    if (!currentlyPlaying?.is_playing) return

    // If the currently playing track is the same as the current track and
    //  the difference between the current track's duration and the progress is less than 5 seconds, do nothing.
    if (
      currentTrack?.id &&
      currentlyPlaying.item.id === currentTrack?.id &&
      Math.abs(currentlyPlaying.progress_ms - currentTrack.duration_ms) < 5000
    )
      return

    playTrack(currentlyPlaying.item.id, currentlyPlaying.progress_ms)
  }, [currentlyPlaying, currentTrack, isListeningAlong, playTrack])

  // Toggle playback: play a track if it's not current; otherwise, pause/resume.
  const togglePlayback = (trackId: string, positionMs?: number) => {
    if (!token) loginToSpotify()
    if (!player) return
    if (trackId !== currentTrackId || !isListeningAlong) {
      playTrack(trackId, positionMs)
    } else {
      if (isPlaying) {
        player.pause()
      } else {
        player.resume()
      }
    }
  }

  const loginToSpotify = () => {
    window.location.href = `${API_BASE}/api/music/spotify/login`
  }

  const value: SpotifyContextProps = {
    tracks,
    loading,
    error,
    player,
    deviceId,
    token,
    currentTrackId,
    isPlaying,
    isListeningAlong,
    setIsListeningAlong,
    togglePlayback,
    topTracksTimeRange,
    setTopTracksTimeRange,
    isLoadingTopTracks,
    currentlyPlaying,
    loginToSpotify,
  }

  return (
    <SpotifyContext.Provider value={value}>{children}</SpotifyContext.Provider>
  )
}

export const useSpotify = () => {
  const context = useContext(SpotifyContext)
  if (!context) {
    throw new Error('useSpotify must be used within a SpotifyProvider')
  }
  return context
}
