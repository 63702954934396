import React from 'react'
import { formatMsTimeToMMSS } from '../utils/time'

interface LinearProgressBarProps {
  currentMs: number
  totalMs: number
}

const LinearProgressBar: React.FC<LinearProgressBarProps> = ({
  currentMs,
  totalMs,
}) => {
  return (
    <div className="flex items-center flex-row mt-2 space-x-1">
      <p className="text-sm text-gray-600">{formatMsTimeToMMSS(currentMs)}</p>
      <div className="w-full bg-gray-300 rounded-full h-2">
        <div
          className="bg-blue-500 opacity-50 h-full rounded-full transition-all duration-300"
          style={{ width: `${(currentMs / totalMs) * 100}%` }}
        />
      </div>
      <p className="text-sm text-gray-600">{formatMsTimeToMMSS(totalMs)}</p>
    </div>
  )
}

export default LinearProgressBar
