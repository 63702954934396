// src/pages/BlogPost.tsx
import React from 'react'
import { useParams } from 'react-router-dom'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import post2024Reflection from '../posts/2024-reflection.md'

const POSTS: Record<string, string> = {
  '2024-reflection': post2024Reflection,
}

const BlogPost: React.FC = () => {
  const { id } = useParams<{ id: string }>()

  return (
    <div className="prose mx-auto p-4">
      {id && POSTS[id] ? (
        <ReactMarkdown remarkPlugins={[remarkGfm]}>{POSTS[id]}</ReactMarkdown>
      ) : (
        <p>Post not found</p>
      )}
    </div>
  )
}

export default BlogPost
