// src/components/MarqueeText.tsx
import React, { useRef, useState, useEffect } from 'react'

export interface MarqueeTextItem {
  text: string
  link: string
}

export interface MarqueeTextProps {
  items: MarqueeTextItem[]
  delimiter?: string
  className?: string
  spacingClassName?: string
}

const MarqueeText: React.FC<MarqueeTextProps> = ({
  items,
  delimiter = ', ',
  className = '',
  spacingClassName = 'mx-2',
}) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const [isOverflow, setIsOverflow] = useState(false)

  // Check if the content overflows its container
  const checkOverflow = () => {
    if (containerRef.current) {
      setIsOverflow(
        containerRef.current.scrollWidth > containerRef.current.clientWidth,
      )
    }
  }

  useEffect(() => {
    checkOverflow()
    window.addEventListener('resize', checkOverflow)
    return () => window.removeEventListener('resize', checkOverflow)
  }, [items, containerRef])

  // Helper function to render all items with individual links
  const renderItems = (keyPrefix: string) =>
    items.map((item, index) => (
      <React.Fragment key={`${keyPrefix}-${index}`}>
        <a
          href={item.link}
          target="_blank"
          rel="noopener noreferrer"
          className="inline-block hover:underline"
        >
          {item.text}
        </a>
        {index < items.length - 1 && <span>{delimiter}</span>}
      </React.Fragment>
    ))

  return (
    // Wrap with a 'group' so we can use group-hover for the marquee animation.
    <div className={`group ${className}`}>
      {/* This container is measured for overflow */}
      <div ref={containerRef} className="overflow-hidden">
        {isOverflow ? (
          // Duplicate the list of clickable items for a continuous marquee effect
          <div className="inline-block whitespace-nowrap group-hover:animate-marquee">
            {renderItems('first')}
            <span className={spacingClassName} />
            {renderItems('second')}
          </div>
        ) : (
          <span className="whitespace-nowrap">{renderItems('single')}</span>
        )}
      </div>
    </div>
  )
}

export default MarqueeText
