export interface Artist {
  id: string
  name: string
  external_urls: ExternalUrls
}

export interface Image {
  height: number
  width: number
  url: string
}

export interface SpotifyPlayerState {
  paused: boolean
  shuffle: boolean
  position: number
  duration: number
  track_window: {
    current_track?: Track
  }
}

export interface Album {
  images: Image[]
  external_urls: ExternalUrls
}

export interface Track {
  id: string
  name: string
  artists: Artist[]
  album: Album
  duration_ms: number
  external_urls: ExternalUrls
}

export interface ExternalUrls {
  spotify: string
}

export interface SpotifyApiResponse {
  items: Track[]
}

export enum TimeRange {
  SHORT_TERM = 'short_term',
  MEDIUM_TERM = 'medium_term',
  LONG_TERM = 'long_term',
}

export interface CurrentlyPlayingInfo {
  item: Track
  is_playing: boolean
  progress_ms: number
}
