// src/pages/Home.tsx
import React from 'react'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import LatestPosts from '../components/LatestPosts'
import SpotifyCurrentlyPlaying from '../components/spotify/SpotifyCurrentlyPlaying'
import SpotifyTopTracks from '../components/spotify/SpotifyTopTracks'
import about from '../posts/about.md'

const Home: React.FC = () => {
  return (
    <div className="w-full">
      {/* Featured Posts / About Section */}
      <section className="py-12 px-4 bg-white">
        <div className="max-w-5xl mx-auto">
          {/* Change flex direction: column on small screens, row on md+ */}
          <div className="flex flex-col md:flex-row gap-8">
            {/* Left Column (About) */}
            <div className="prose w-full md:w-2/3">
              <ReactMarkdown remarkPlugins={[remarkGfm]}>{about}</ReactMarkdown>
            </div>

            {/* Right Column (Latest Posts) */}
            <div className="w-full md:w-1/3 space-y-8">
              <LatestPosts />
              <SpotifyCurrentlyPlaying />
              <SpotifyTopTracks />
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Home
