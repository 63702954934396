import React from 'react'
import { Link, useLocation } from 'react-router-dom'

function Navbar() {
  const [isShrunk, setIsShrunk] = React.useState(false)
  const [scrollProgress, setScrollProgress] = React.useState(0)
  const location = useLocation()

  // Determine if we're on a blog-post page (e.g. /blog/123)
  // Adjust the condition as needed to match your URL structure
  const isBlogPostPage = location.pathname.startsWith('/blog/')

  React.useEffect(() => {
    function handleScroll() {
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop
      const scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight
      const clientHeight =
        document.documentElement.clientHeight || window.innerHeight

      // Calculate the scroll progress as a percentage
      const scrolled = (scrollTop / (scrollHeight - clientHeight)) * 100
      setScrollProgress(scrolled)

      // Shrink the navbar if scrolled more than 60px
      if (scrollTop > 60) {
        setIsShrunk(true)
      } else {
        setIsShrunk(false)
      }
    }

    window.addEventListener('scroll', handleScroll)
    // Call it once to set the initial state
    handleScroll()

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <div className="w-full flex flex-row justify-center items-center">
      <nav
        className={`
          fixed top-0 w-full z-50 bg-white transition-all duration-500
          ${isShrunk ? 'mt-4 w-2/3 py-4 shadow-lg' : 'py-4 mt-4'}
        `}
      >
        <div className="max-w-4xl mx-auto flex items-center justify-center px-1">
          <Link to="/" className="text-xl font-semibold text-gray-800">
            David Dalmaso
          </Link>
        </div>

        {/* Conditionally render the progress bar if it's a blog post page */}
        {isBlogPostPage && (
          <div
            className="absolute bottom-0 left-0 h-[2px] bg-black"
            style={{ width: `${scrollProgress}%` }}
          />
        )}
      </nav>
    </div>
  )
}

export default Navbar
