// src/components/SpotifyTrack.tsx
import React from 'react'
import { Play, Pause } from 'lucide-react'
import { Track } from '../../types/spotify'
import SpotifyTrackCommon from './SpotifyTrackCommon'

interface SpotifyTrackProps {
  track: Track
  currentTrackId: string | null
  isPlaying: boolean
  togglePlayback: (trackId: string, progressMs?: number) => void
}

const SpotifyTrack: React.FC<SpotifyTrackProps> = ({
  track,
  currentTrackId,
  isPlaying,
  togglePlayback,
}) => {
  return (
    <SpotifyTrackCommon
      track={track}
      button={
        <button
          onClick={() => togglePlayback(track.id)}
          className="p-2 bg-blue-500 hover:bg-blue-600 rounded flex items-center justify-center"
        >
          {currentTrackId === track.id && isPlaying ? (
            <Pause size={20} className="text-white" />
          ) : (
            <Play size={20} className="text-white" />
          )}
        </button>
      }
    />
  )
}

export default SpotifyTrack
