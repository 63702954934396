// src/components/SpotifyPlayer.tsx
import React from 'react'
import SpotifyTrack from './SpotifyTrack'
import { useSpotify } from '../../context/SpotifyContext'
import { TimeRange, Track } from '../../types/spotify'

const TIME_RANGE_LABELS: Record<TimeRange, string> = {
  [TimeRange.SHORT_TERM]: 'Recent',
  [TimeRange.MEDIUM_TERM]: 'Mid-term',
  [TimeRange.LONG_TERM]: 'This year',
}

const SpotifyTopTracks: React.FC<{ className?: string }> = ({ className }) => {
  const {
    tracks,
    loading,
    error,
    currentTrackId,
    isPlaying,
    togglePlayback,
    isLoadingTopTracks,
    topTracksTimeRange,
    setTopTracksTimeRange,
  } = useSpotify()

  if (loading) return <p>Loading top tracks...</p>
  if (error) return <p className="text-red-600">{error}</p>

  const handleTimeRangeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTopTracksTimeRange(e.target.value as TimeRange)
  }

  return (
    <div className={className}>
      <h2 className="text-xl font-semibold text-gray-800 mb-4">
        My top tracks
      </h2>
      <div className="spotify-player p-2 bg-gray-100 text-gray-900 rounded-md shadow">
        {/* Radio buttons for selecting the time range */}
        <div className="mb-4">
          <fieldset className="flex gap-2 flex-row justify-around">
            <legend className="sr-only">Select Time Range</legend>
            {Object.entries(TIME_RANGE_LABELS).map(([key, label]) => (
              <label key={key} className="flex items-center cursor-pointer">
                <input
                  type="radio"
                  name="timeRange"
                  value={key}
                  checked={topTracksTimeRange === key}
                  onChange={handleTimeRangeChange}
                  className="mr-1"
                />
                <span className="text-sm">{label}</span>
              </label>
            ))}
          </fieldset>
        </div>

        <div className="h-[500px] overflow-y-auto space-y-2 scrollbar-thin scrollbar-thumb-gray-300">
          {isLoadingTopTracks ? (
            <div className="w-full h-full flex items-center justify-center">
              <p>Loading top tracks...</p>
            </div>
          ) : (
            tracks.map((track: Track) => (
              <SpotifyTrack
                key={track.id}
                track={track}
                currentTrackId={currentTrackId}
                isPlaying={isPlaying}
                togglePlayback={togglePlayback}
              />
            ))
          )}
        </div>
        {/* Section to note powered by Spotify */}
        <div className="text-xs text-gray-600 mt-4 w-full items-center flex-row flex justify-between">
          <div>
            Powered by{' '}
            <a
              href="https://developer.spotify.com/documentation/web-api/"
              target="_blank"
              rel="noreferrer"
              className="text-blue-600"
            >
              Spotify API
            </a>
          </div>
          {/* Spotify logo */}
          <div>
            <a
              href="https://www.spotify.com/"
              target="_blank"
              rel="noreferrer"
              className="text-blue-600"
            >
              <img
                src="/spotify-logo.png"
                alt="Spotify Logo"
                className="w-5 h-5 inline"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SpotifyTopTracks
